import { buildUrlParam } from "../util";

// todo: message => detail
export interface ValidationError {
  key: string;
  message: string;
}

export interface Error {
  validation_error: ValidationError[];
}

export interface AuthenticationError {
  message: string;
  expired: boolean;
}

export interface ErrorV2 {
  // TODO: 種類ありすぎるので各画面で判断。
  validation_error?: any;
  message?: string;
  error?: string;
  order_error?: OrderErrorDetail;
}

export interface OrderErrorDetail {
  code: OrderErrorCode;
}

export type OrderErrorCode =
  | "stock_out"
  | "stock_not_enough"
  | "had_ended"
  | "not_start_yet"
  | "course_expired"
  | "course_not_available"
  | "payment"
  | "undeliverable"
  | "invalid_coupon"
  | "duplicated_course";

export interface ValidationErrorV2 {
  [key: string]: string | ValidationErrorV2;
}

export type Result<T, E> = Success<T, E> | Failure<T, E> | Unauthorized<T, E>;

export interface SuccessFn<T> {
  (v: T): void;
}

export interface FailureFn<E> {
  (e: E): void;
}

export interface ResultEvent<T, E> {
  successFn?: SuccessFn<T>;
  failureFn?: FailureFn<E>;
}

export class Success<T, E> {
  constructor(readonly value: T) {}

  type = "success" as const; // ここを追加
  isSuccess = (): this is Success<T, E> => true;
  isFailure = (): this is Failure<T, E> => false;
}

export class Failure<T, E> {
  constructor(readonly value: E) {}

  type = "failure" as const; // ここを追加
  failureType = "general";
  isSuccess = (): this is Success<T, E> => false;
  isFailure = (): this is Failure<T, E> => true;
}

export class Unauthorized<T, E> extends Failure<T, E> {
  failureType = "unauthorized";
}

export interface PageInfo {
  total: number;
  total_pages: number;
  per_page: number;
  page: number;
  next_page: number | null;
  prev_page: number | null;
  offset: number | null;
}

export class BaseClientService {
  API_BASE_URL = process.env.API_BASE_URL;
  API_CF_BASE_URL = process.env.API_CF_BASE_URL;

  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  async doSearch<T, E>(url: string, resultEvents?: ResultEvent<T, E>): Promise<Result<T, E>> {
    const response = await fetch(encodeURI(url), {
      credentials: "include",
      headers: this.headers,
    });
    return this.request<T, E>(response, resultEvents);
  }

  async doGet<T, E>(baseUrl: string, id: string, resultEvents?: ResultEvent<T, E>): Promise<Result<T, E>> {
    const url = `${baseUrl}/${id}`;
    const response = await fetch(url, {
      credentials: "include",
      headers: this.headers,
    });
    return this.request<T, E>(response, resultEvents);
  }

  async doPut<T, E>(baseUrl: string, id: string, body: string): Promise<Result<T, E>> {
    const url = `${baseUrl}/${id}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: this.headers,
      body,
    });
    return this.request<T, E>(response);
  }

  async doPost<T, E>(url: string, body: string): Promise<Result<T, E>> {
    const response = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: this.headers,
      body,
    });
    return this.request<T, E>(response);
  }

  async doDelete<T, E>(url: string, body: string): Promise<Result<T, E>> {
    const response = await fetch(url, {
      credentials: "include",
      method: "DELETE",
      headers: this.headers,
      body,
    });
    return this.request<T, E>(response);
  }

  public buildUrl(url: string, query: Record<string, string | number | undefined>): string {
    const params = buildUrlParam(query);
    if (params.length > 0) {
      return `${url}?${params}`;
    } else {
      return url;
    }
  }

  public async fetcher<T>(url: string): Promise<T> {
    const response = await fetch(encodeURI(url), {
      credentials: "include",
      headers: this.headers,
    });
    const data = await response.json();
    if (200 <= response.status && response.status <= 300) {
      return data;
    } else {
      throw data;
    }
  }

  public async request<T, E>(response: Response, resultEvents?: ResultEvent<T, E>): Promise<Result<T, E>> {
    if (response.ok) {
      const success = new Success<T, E>((await response.json()) as T);
      resultEvents?.successFn?.(success.value);
      return success;
    } else if (response.status === 404) {
      const failure = new Failure<T, E>({ error: "not found" } as any as E);
      resultEvents?.failureFn?.(failure.value);
      return failure;
    } else if (response.status === 401) {
      return new Unauthorized<T, E>((await response.json()) as E);
    } else {
      const failure = new Failure<T, E>((await response.json()) as E);
      resultEvents?.failureFn?.(failure.value);
      return failure;
    }
  }
}
