import { useMemo } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const buildUrlParam = (
  obj: Record<string, string | number | undefined>
): string => {
  return Object.entries(obj)
    .filter(([, value]) => value !== undefined)
    .filter(([, value]) => !(typeof value === "string" && value.length === 0))
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

export const toDateFormat = (dateString: string): string => {
  return new Date(dateString).toLocaleString();
};

// https://github.com/vercel/next.js/issues/2473
export const isClient = (): boolean => {
  return typeof window !== "undefined";
};
export const isServer = (): boolean => {
  return typeof window === "undefined";
};

export const isIeBrowser = (): boolean => {
  return (
    isClient() &&
    (window.navigator.userAgent.indexOf("MSIE") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1)
  );
};

export const countPadStringArray = (count: number, pad: number): string[] => {
  return useMemo(() => {
    const countString = count.toString();
    return countString.padStart(pad, "0").split("");
  }, [count]);
};

export const useDeviceType = (): { pc: boolean; sp: boolean } => {
  const theme = useTheme();
  // 初期状態のundefinedな状態がfalseで判定されるのでPC/SPを分けて定義してる
  const isPc = useMediaQuery(theme.breakpoints.up("md"));
  const isSp = useMediaQuery(theme.breakpoints.down("sm"));
  return { pc: isPc, sp: isSp };
};

export const doNothing = (): void => {
  // do nothing.
}
